import React, { useEffect } from "react";
import { CloseIcon } from "./icons";
import {
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "./styles";

const CommonModal = ({
  show,
  onClose,
  title,
  body,
  onConfirm,
  onCancel,
  type,
  showFooter = true, 
}) => {
  const onKeyboardClick = (event) => {
    if ((event.charCode || event.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", onKeyboardClick);
    return () => {
      document.body.removeEventListener("keydown", onKeyboardClick);
    };
  }, []);

  return (
    <ModalContainer show={show} onClick={onClose}>
    <ModalContent show={show} onClick={(event) => event.stopPropagation()}>
      <ModalHeader>
        <ModalTitle>
          {title}
          <i onClick={onClose}>
            <CloseIcon fill="#81d8f8" />
          </i>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      {showFooter && ( 
        <ModalFooter>
          <button onClick={() => onCancel()}>Cancelar</button>
          <button onClick={() => onConfirm()}>
            {type ? 
              title.toLowerCase().includes("desactivar") ? "Desactivar" : "Activar"
              : "Remover"
            }
          </button>
        </ModalFooter>
      )}
    </ModalContent>
  </ModalContainer>
  );
};

export default CommonModal;
