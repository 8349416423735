import React from 'react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { MenuItem, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useSelector } from 'react-redux';
import { IconsContainer } from './Styles';
import Avatar from './Avatar';
import { useLocation } from 'react-router-dom';

export function isCurrentRoute(targetPath) {
  return window.location.pathname === targetPath;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '13px !important',
    fontWeight: 'bold !important',
    color: '#3B4144',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: '#3B4144',
    borderBottom: '1px solid #F6F6F6',
  },
  [`.LinesEllipsis`]: {
    fontSize: '13px !important',
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  [`&.${tableSortLabelClasses.head}`]: {
    fontWeight: 'bold !important',
    fontSize: '13px !important',
    color: '#3B4144',
  },
  [`&.${tableSortLabelClasses.body}`]: {
    fontSize: '13px !important',
    color: '#3B4144',
    borderBottom: '1px solid #F6F6F6',
  },
  [`&.MuiTableSortLabel-root`]: {
    fontSize: '13px !important',
    color: '#3B4144',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide first border
  '&:first-of-type td, &:first-of-type th': {
    borderBottom: '1px solid #F6F6F6',
  },
}));

const TableComponent = ({
  items,
  hasImage,
  displayColumns,
  actions,
  onActionChange,
  imgUrlBase,
  onSortableHeadClick,
  withHeaderButton,
  onClickHeaderButton,
  isInstituition,
}) => {
  const isLoadingEntity = useSelector((state) => state.adminReducer.isLoading);
  const isLoading = useSelector((state) => state.usersReducer.isLoading);
  const theme = useTheme();

  console.log('Items: ', items);

  var date = null;
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 0,
        boxShadow: 'none',
        fontFamily: 'Poppins !important',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label='simple table'
      >
        <TableHead>
          <TableRow>
            {hasImage && <StyledTableCell align='left'></StyledTableCell>}
            {displayColumns.map((col, index) => {
              return (
                <StyledTableCell key={index}>
                  <StyledTableSortLabel
                    key={index}
                    align='left'
                    direction={col.direction}
                    onClick={() => onSortableHeadClick(index)}
                  >
                    {col.label}
                  </StyledTableSortLabel>
                </StyledTableCell>
              );
            })}
            {withHeaderButton ? (
              <StyledTableCell align='center' width='100px'>
                <AddBoxIcon
                  style={{ fontSize: '2rem', cursor: 'pointer' }}
                  onClick={onClickHeaderButton}
                />
              </StyledTableCell>
            ) : (
              <StyledTableCell align='left' width='100px'>
                Operação
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        {!isLoading && !isLoadingEntity && items && items.length > 0 && (
          <TableBody>
            {items.map((row, index) => (
              <StyledTableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {hasImage && (
                  <StyledTableCell component='th' scope='row' my='auto'>
                    <Avatar imgUrlBase={imgUrlBase} itemId={row.id} />
                  </StyledTableCell>
                )}
                {displayColumns.map((col, index) => {
                  return (
                    <StyledTableCell key={index} align='left'>
                      {col?.field === 'type'
                        ? eval(`row?.${col?.field}`) === 'NATIONAL'
                          ? 'Nacional'
                          : eval(`row?.${col?.field}`) === 'FOREIGN'
                            ? 'Estrangeiro'
                            : eval(`row?.${col?.field}`) === 'OFFICIAL'
                              ? 'Oficial'
                              : eval(`row?.${col?.field}`)
                        : //
                        col?.field === 'levelOfEducation'
                          ? eval(`row?.${col?.field}`) === 'ELEMENTARY'
                            ? 'Ensino Primário'
                            : eval(`row?.${col?.field}`) === 'SECONDARY_I'
                              ? 'I Ciclo do Secundário'
                              : eval(`row?.${col?.field}`) === 'SECONDARY_II'
                                ? 'Ensino Médio - II Ciclo do Secundário'
                                : eval(`row?.${col?.field}`) === 'BACHELOR'
                                  ? 'Bacharelado'
                                  : eval(`row?.${col?.field}`) === 'DEGREE'
                                    ? 'Licenciatura'
                                    : eval(`row?.${col?.field}`) === 'MASTER'
                                      ? 'Mestrado'
                                      : eval(`row?.${col?.field}`) === 'POST_GRADUATION'
                                        ? 'Pós Graduação'
                                        : eval(`row?.${col?.field}`) === 'DOCTORAL'
                                          ? 'Doutorado'
                                          : eval(`row?.${col?.field}`) === 'POST_DOCTORAL'
                                            ? 'Pós Doutorado'
                                            : eval(`row?.${col?.field}`)
                          : //
                          col?.field === 'politicalPosition'
                            ? eval(`row?.${col?.field}`) === true
                              ? 'Sim'
                              : 'Não'
                            : col?.field === 'nature'
                              ? eval(`row?.${col?.field}`) === 'PUBLIC'
                                ? 'Público'
                                : 'Privado'
                              : col?.field === 'createdAt'
                                ? (date = new Date(
                                  eval(`row?.${col?.field}`)
                                ).toLocaleDateString()
                                  ? new Date(
                                    eval(`row?.${col?.field}`)
                                  ).toLocaleDateString()
                                  : 'Não')
                                : col?.field === 'status'
                                  ? eval(`row?.${col?.field}`) === 'CREATION_PROCESS'
                                    ? 'Em Criação'
                                    : eval(`row?.${col?.field}`) === 'ACTIVE'
                                      ? 'Activo'
                                      : eval(`row?.${col?.field}`) === 'EXTINCT'
                                        ? 'Extinto'
                                        : 'Desactivado'
                                  : col?.field === 'regime'
                                    ? eval(`row?.${col?.field}`) === 'GENERAL'
                                      ? 'Geral'
                                      : eval(`row?.${col?.field}`) === 'SPECIAL'
                                        ? 'Especial'
                                        : 'Não Definido'
                                    : col?.field === 'classification'
                                      ? eval(`row?.${col?.field}`) === 'LOCAL_ADMINISTRATION'
                                        ? 'Administração Local'
                                        : eval(`row?.${col?.field}`) ===
                                          'CENTRAL_ADMINISTRATION '
                                          ? 'Administração Central'
                                          : 'Não Especificado'
                                      : eval(`row?.${col?.field.split('[')[0]}`) !== undefined
                                        ? eval(`row?.${col?.field}`)
                                        : col?.field === "code" && isCurrentRoute("/admin/configuracoes/templates")
                                          ? eval(`row?.type`)
                                          : ''
                      }
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell align='right'>
                  <IconsContainer>
                    <Select
                      value={'...'}
                      onChange={(e) => onActionChange(e.target.value, row)}
                      sx={{
                        '& .MuiSelect-icon': {
                          display: 'none',
                        },
                        '& .MuiSvg-icon': {
                          fontSize: '1.5rem !important',
                        },
                        '& .MuiSelect-select': {
                          paddingLeft: '21px !important',
                          paddingRight: '21px !important',
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderWidth: '1 !important',
                          borderColor: theme.custom.borderColorGreyLight,
                          margin: '10px',
                        },
                      }}
                    >
                      <MenuItem
                        value='...'
                        sx={{
                          display: 'none',
                        }}
                      >
                        <MoreVertRoundedIcon />
                      </MenuItem>

                      {typeof actions === 'function'
                        ? actions(row).map((action, index) => {
                          return action.status ? (
                            action.status.includes(row.status) && (
                              <MenuItem key={index} value={action.label}>
                                {action.label}
                              </MenuItem>
                            )
                          ) : (
                            <MenuItem key={index} value={action.label}>
                              {action.label}
                            </MenuItem>
                          );
                        })
                        : actions.map((action, index) => {
                          return action.status ? (
                            action.status.includes(row.status) && (
                              <MenuItem key={index} value={action.label}>
                                {action.label}
                              </MenuItem>
                            )
                          ) : (
                            <MenuItem key={index} value={action.label}>
                              {action.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </IconsContainer>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
